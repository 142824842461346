<template>
<v-list-item three-line>
    <v-list-item-content>
        <v-layout>
            <v-flex xs4>
                <v-list-item-title>{{coupon.code}}</v-list-item-title>
                <v-list-item-subtitle class="card-subtitle-font">{{coupon.couponType}}</v-list-item-subtitle>
            </v-flex>
            <v-flex xs7>
                <v-list-item-title>Start: {{coupon.startDate|dateFormat}}</v-list-item-title>
                <v-list-item-subtitle>Expire: {{coupon.expiryDate|dateFormat}}</v-list-item-subtitle>
                <v-list-item-subtitle class="card-subtitle-font">{{coupon.discountType}}</v-list-item-subtitle>
            </v-flex>
            <v-flex xs2>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="coupon.isActive" v-bind="attrs" v-on="on" class="active-icon mt-2">mdi-check-circle-outline</v-icon>
                    </template>
                    <span>Enabled</span>
                </v-tooltip>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="!coupon.isActive" v-bind="attrs" v-on="on" class="deactive-icon mt-2">mdi-cancel</v-icon>
                    </template>
                    <span>Disabled</span>
                </v-tooltip>
            </v-flex>
            <v-menu left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon >mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(item, index) in icons" :key="index">
                <v-btn fab dark x-small class="ma-2" :style="theme">
                    <v-icon :show="isAllowed(item.permission)" @click="performAction(item,coupon._id)">{{ item.name }}</v-icon>
                </v-btn>
            </v-list-item>
                </v-list>
            </v-menu>
        </v-layout>
    </v-list-item-content>
</v-list-item>
</template>

<script>
import moment from 'moment'
export default {
    props: ["icons", "coupon"],
};
</script>

<style scoped>
</style>
