<template>
<div>
    <div class="display-block">
        <v-alert v-if="msg" :type="msgType">
            <ul v-for="item in msg" :key="item.errors">
                <li>{{item}}</li>
            </ul>
        </v-alert>
        <page-header v-model="search" title="Coupons" permission="createCoupon" @click="navigateTo('/app/coupons/0')"></page-header>
    </div>
    <div>
        <v-data-table :headers="headers" :items="items" class="elevation-1" :search="search" :items-per-page="items.length">
            <template v-slot:item.isActive="{item}" v-if="$vuetify.breakpoint.smAndUp">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="item.isActive" v-bind="attrs" v-on="on" class="active-icon">mdi-check-circle-outline</v-icon>
                    </template>
                    <span>Enabled</span>
                </v-tooltip>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="!item.isActive" v-bind="attrs" v-on="on" class="deactive-icon">mdi-cancel</v-icon>
                    </template>
                    <span>Disabled</span>
                </v-tooltip>
            </template>
            <template v-slot:item.showOnStore="{item}" v-if="$vuetify.breakpoint.smAndUp">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="item.showOnStore" v-bind="attrs" v-on="on" class="active-icon">mdi-check-circle-outline</v-icon>
                    </template>
                    <span>Enabled</span>
                </v-tooltip>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="!item.showOnStore" v-bind="attrs" v-on="on" class="deactive-icon">mdi-cancel</v-icon>
                    </template>
                    <span>Disabled</span>
                </v-tooltip>
            </template>
            <template  v-slot:item.startDate="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                {{item.startDate|dateFormat}}
            </template>
            <template  v-slot:item.expiryDate="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                {{item.expiryDate|dateFormat}}
            </template>
            <template v-slot:item.action="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                <template v-for="icon in icons">
                    <action-button :key="icon.name" :icon="icon.name" :show="isAllowed(icon.permission)" @click="icon.name=='mdi-delete'?deleteCoupon(item._id):$router.push(icon.path+''+item._id)"></action-button>
                </template>
            </template>
            <template v-slot:item="{item}" v-if="$vuetify.breakpoint.xsOnly">
                <coupon-card :coupon="item" :icons="icons" @delete="removeItem"></coupon-card>
            </template>
        </v-data-table>
        <pagination :api="apiCall" v-model="items" sort="+expiryDate"></pagination>
    </div>
    <v-alert v-if="msg" :type="msgType">
        <ul v-for="item in msg" :key="item.errors">
            <li>{{item}}</li>
        </ul>
    </v-alert>
</div>
</template>

<script>
import CouponCard from '@/components/CouponCard'
import ActionButton from '@/components/ActionButton'
import appConstants from '@/utils/appConstants'
export default {
    components: {
        ActionButton,
        CouponCard
    },
    data() {
        return {
            search: "",
            headers: [{
                    text: "Code",
                    value: "code"
                },
                {
                    text: "Coupon Type",
                    value: 'couponType'
                },
                {
                    text:"Start Date",
                    value:'startDate'
                },
                {
                    text:"Expiry Date",
                    value:'expiryDate'
                },
                {
                    text:"Discount Type",
                    value:'discountType'
                },
                {
                    text: "Active",
                    value: 'isActive'
                },
                {
                    text: "Show On WebStore",
                    value: 'showOnStore'
                },
                {
                    text: 'Actions',
                    value: 'action',
                    align: 'right'
                }
            ],
            search: '',
            items: [],
            icons: [{
                    name: "mdi-eye",
                    path: "/app/coupons/view/",
                    permission: "viewCoupon"
                }, {
                    name: "mdi-pencil",
                    path: "/app/coupons/",
                    permission: "editCoupon"
                },
                {
                    name: "mdi-delete",
                    permission: "deleteCoupon"
                }
            ],
            apiCall:appConstants.COUPONS_API
        };
    },
    methods: {
        async deleteCoupon(id) {
            try {
                await this.deleteItem("Are you sure you want to delete this Coupon?", appConstants.COUPONS_API + "/" + id)
                this.items.splice(this.items.findIndex(rec => rec._id == id), 1)
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data.errors
            }
        },
        removeItem(id) {
            this.deleteCoupon(id)
        }
    }
};
</script>

<style scoped>
.format {
    padding: 1%;
}
</style>
